import React, { useState, useEffect } from "react";

// packages
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useSearchParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import ReactTooltip from "react-tooltip";
import { v4 as uuidv4 } from 'uuid';
import { ImCross } from "react-icons/im";
import { IconContext } from "react-icons";
import Loader from "../Loader";

// functions

// components

// https://plugin.bimroom.com/redirect?id=3131321313&version=1&action=replace&revit=2022
const Redirect = () => {
  const [queryParameters] = useSearchParams();
  const navigate = useNavigate();
  const {isLoading, setIsLoading} = useState(true);
  const id = queryParameters.get("id")
  //const id = "medaslim";
  const version = queryParameters.get("version")
  const revitVersion = queryParameters.get("revit")
  const action = queryParameters.get("action")

  useEffect(() => {
    // bimroom library - action === "catalog"
    // properties - action === "product" & id === product_id
    // find similar - action === "catalog" & id === product_id
    // replace - action === "catalog" & id === product_id
    // help - action === "help"
    // contact - action === "contact"
    // about-us - action === "aboutus"
    // wishlist - action === "favorite"
    // b-collection - action === "generic"
    // user - action === "user"
    // company - action === "company" & id === company_slug

    if (action === "catalog") {
      if (id) {
        // get product data based on Bimroom product id, and retrieve filter parameters and pass on to Algolia as URL parameters
        
        navigate("/product/search/Windows");
      } else {
        navigate("/product")
      }
    } else if (action === "replace") {
      if (id) {
        // get product data based on Bimroom product id, and retrieve filter parameters and pass on to Algolia as URL parameters
        navigate("/product/search/Windows");
      } else {
        navigate("/product")
      }
    } else if (action === "product") {
      if (id) {
        // get product slug based on Bimroom product id, and go to product page (if available)

        const test = "medaslim";
        //setIsLoading(false);
        navigate("/product/" + test);
      } else {
        navigate("/product")
      }
    } else if (action === "help") {
      navigate("/blog")
    } else if (action === "aboutus") {
      navigate("/blog")
    } else if (action === "wishlist") {
      navigate("/favorite")
    } else if (action === "generic") {
      navigate("/product/search/?brands=Bimroom%2520Collection")
    } else if (action === "company") {
      if (id) {
        // get brand ID and convert to Brand name (and encode)

        navigate("/product/search/?brands=" + id)
      } else {
        navigate("/product")
      }
    }
  }, [queryParameters])
  
  return (
    <>
      {/* {isLoading ?  */}
        <Loader />
        {/* <div>
          <p><strong>Bimroom ID</strong>: {id}</p>
          <p><strong>Plugin version</strong>: {version}</p>
          <p><strong>Revit version</strong>: {revitVersion}</p>
          <p><strong>Action</strong>: {action}</p>
        </div>
      } */}
    </>
  );

};

export default Redirect;