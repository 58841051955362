import React, { useEffect, useState, useRef } from "react";
import parse from "html-react-parser";
import { maxHeight } from "@mui/system";

export default function Description({ description }) {
  const ref = useRef();
  const [height, setHeight] = useState(0);
  const [showMore, setShowMore] = useState(false);
  const [showButton, setShowButton] = useState(false);
  const [textLength, setTextLength] = useState(0);
  const [descriptionText, setDescriptionText] = useState(0);

  // useEffect(() => {
  //   const width = window.innerWidth;
  //   if (width > 991 && ref?.current) setHeight(ref?.current?.clientHeight);
  // }, [ref]);

  // useEffect(() => {
  //   const length = description?.length;
  //   setTextLength(length);
  //   const test = parse(description);
  //   console.log(test);
  //   if (length > 1000) {
  //     setShowButton(true);
  //   }
  // },[])
  



  useEffect(() => {
    if (ref.current.clientHeight > 300) {
      setShowMore(true);
      setShowButton(true);
    }
  }, [ref]);


  return (
    <>
      <div
        className="description"
        ref={ref}
        style={{
          maxHeight: showMore ? "300px" : "1500px",
        }}
      >
        {parse(`${description}`)}
      </div>
      {showButton ? <div className="description-load-more">
        <span onClick={() => setShowMore(!showMore)}>{showMore ? "Show more" : "Show less"}</span>
      </div> : <></>
      }
          {/* <button className="btn" onClick={() => setShowMore(!showMore)}>{showMore ? "Show less" : "Show more"}</button> */}
    </>
  );
}
