import React from "react";

// packages
import Select from "react-select";
import { useTranslation } from "react-i18next";

// components
import ToggleSwitch from "./Filters/ToggleSwitch";

export default function ToggleSortSection() {
  const { t, i18n } = useTranslation();
  const [selectedOption, setSelectedOption] = React.useState();
  const options = [
    { value: t("product.12"), label: t("product.12") },
    { value: t("product.13"), label: t("product.13") },
    { value: t("product.14"), label: t("product.14") },
  ];

  return (
    <>
      <ToggleSwitch />

      <Select
        defaultValue={
          selectedOption
            ? selectedOption
            : { label: "Default Sorting", value: "Default Sorting" }
        }
        onChange={setSelectedOption}
        options={options}
        className="sort-select"
        isSearchable={false}
      />
    </>
  );
}
