import React from "react";
import "./style.scss";

// packages
import { Link } from "react-router-dom";
import { useMsal, useIsAuthenticated } from "@azure/msal-react";

// images & icons
import logoDark from "../../../assets/navbar/bimroom-dark.svg";
import logoWhite from "../../../assets/navbar/bimroom-white.svg";

//
import { loginRequest } from "../../../authConfig";

export default function NavbarHeader({
  isHomeNavbar,
  leftImage,
  onCLickLeftImage,
  RightImage,
  onCLickRightImage,
  isWhite,
}) {
  const { instance } = useMsal();
  const isAuthenticated = useIsAuthenticated();

  return (
    <div
      className={
        isWhite ? "mobile-navbar mobile-Drawer-navbar" : "mobile-navbar"
      }
    >
      {isAuthenticated ? (
        <img src={leftImage} alt="" width="22px" onClick={onCLickLeftImage} />
      ) : (
        <div className="login" onClick={() => handleLogin(instance)}>
          Login
        </div>
      )}

      <Link to="/" className="logo">
        <img src={isWhite ? logoWhite : logoDark} alt="" width="94px" />
      </Link>

      <img src={RightImage} alt="" width="22px" onClick={onCLickRightImage} />
    </div>
  );
}

function handleLogin(instance) {
  instance.loginPopup(loginRequest).catch((e) => {
    console.error(e);
  });
}
