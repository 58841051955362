import React, { useEffect } from "react";
import TagManager from "react-gtm-module";

// components
import HeroSection from "../components/Home/HeroSection";
import CategorySection from "../components/Home/CategorySection/CategorySection";
import FeaturedObjects from "../components/Home/FeaturedObjects/FeaturedObjects";
import ManufactureSection from "../components/Home/ManufactureSection/ManufactureSection";
import Footer from "../components/Footer";
import UnauthenticatedAccess from "../components/Home/UnauthenticatedAccess";

import {
  AuthenticatedTemplate,
  UnauthenticatedTemplate,
} from "@azure/msal-react";

const Home = () => {
  useEffect(() => {
    TagManager.dataLayer({
      dataLayer: {
        event: "pageview",
        path: "/home",
      },
    });
  }, []);

  useEffect(() => {
    localStorage.setItem('downloading', JSON.stringify('false'));
  }, []);

  return (
    <>
      <AuthenticatedTemplate>
        <HeroSection />
        <CategorySection />
        <FeaturedObjects />
        <ManufactureSection />
        <Footer />
      </AuthenticatedTemplate>
      <UnauthenticatedTemplate>
        <UnauthenticatedAccess />
      </UnauthenticatedTemplate>
    </>
  );
};

export default Home;
