import React, { useState, useEffect, useRef } from "react";
import { FaRegHeart, FaHeart } from "react-icons/fa";
import { getProductByIDs, productToCart } from "../../api";
import { useDispatch, useSelector } from "react-redux";
import { useMsal } from "@azure/msal-react";
import { setUser } from "../../redux/reducers/user-reducer";
import TagManager from "react-gtm-module";
import ModalComponent from "../Modal/Modal";
import { IoIosArrowBack } from "react-icons/io";
import { IoIosArrowForward } from "react-icons/io";
import { HiDownload } from "react-icons/hi";
import { Link } from "react-router-dom";

import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

//
import testData from "./testData.json";

const RelatedProducts = ({ relatedProductIDs }) => {
  const { accounts } = useMsal();
  const dispatch = useDispatch();

  const user = useSelector((store) => store?.user?.user);
  const p_rating = useSelector(
    (store) => store?.product?.productRatingAndReview
  );

  const slider = useRef(null);

  const [relatedProduct, setRelatedProducts] = useState([]);
  const [showModal, setShowModal] = useState(false);
  const [modalData, setModalData] = useState();
  const handleModalClose = () => setShowModal(false);

  const handleModalShow = (data) => {
    TagManager.dataLayer({
      dataLayer: {
        event: "click",
        button: "download",
        product: "productID",
      },
    });
    setModalData(data);
    setShowModal(true);
  };

  const handleCart = (product) => {
    if (accounts[0]) {
      let payload = {
        tenantId: accounts[0]?.localAccountId,
        name: accounts[0]?.name,
        email: accounts[0]?.username,
        productID: product?.id,
      };

      productToCart(payload)
        .then((data) => {
          dispatch(setUser(data.user));
        })
        .catch((err) =>
          console.log("related product page api error =>", err.message)
        );
    } else {
      alert("Please login first...");
    }
  };

  // TODO: DELETE COMMENTED CODE WHEN CLIENT TEST DESIGN
  // useEffect(() => {
  //   setTimeout(() => {
  //     setRelatedProducts([
  //       testData,
  //       testData,
  //       testData,
  //       testData,
  //       testData,
  //       testData,
  //     ]);
  //   }, [3000]);
  // }, []);

  // TODO: UNDO COMMENTED CODE WHEN CLIENT TEST DESIGN
  // useEffect(() => {
  //   let isCancelled = false;

  //   getProductByIDs({ productIDs: relatedProductIDs })
  //     .then((data) => {
  //       if (!isCancelled) setRelatedProducts(data.data);
  //     })
  //     .catch((err) => console.log("related product error =>", err.message));

  //   return () => {
  //     isCancelled = true;
  //   };
  // }, [relatedProductIDs]);

  const settings = {
    slidesToShow: 3.5,
    slidesToScroll: 1,
    infinite: false,
    autoplay: false,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 2.5,
        },
      },
      {
        breakpoint: 900,
        settings: {
          slidesToShow: 3,
        },
      },
      {
        breakpoint: 767,
        settings: {
          slidesToShow: 2,
        },
      },

      {
        breakpoint: 575,
        settings: {
          slidesToShow: 1,
        },
      },
    ],
  };

  const getImageFromAzureStorage = (image) => {
    return `https://cdn.bimroom.com/images/${image}`;
  };

  return (
    <>
      {relatedProduct.length > 0 && (
        <div className="related-product-section">
          <div className="header-container">
            <div className="title">Related Objects</div>

            <div className="sliderButton">
              <div
                className="btn-wrapper"
                onClick={() => slider?.current?.slickPrev()}
              >
                <IoIosArrowBack className="back" />
              </div>

              <div
                className="btn-wrapper"
                onClick={() => slider?.current?.slickNext()}
              >
                <IoIosArrowForward className="forward" />
              </div>
            </div>
          </div>

          <Slider {...settings} className="product-wrapper" ref={slider}>
            {relatedProduct.length > 0 &&
              relatedProduct.map((data, i) => {
                const getRImage = data?.meta_data?.find(
                  (ele) => ele?.key === "r_image"
                );
                return (
                  <div className="p_card" key={i}>
                    <Link to={`/product/${data?.slug}`} className="p_body">
                      <div className="thumbnail">
                        {getRImage?.value && (
                          <img
                            src={getImageFromAzureStorage(getRImage?.value)}
                            alt="#"
                          />
                        )}
                        {/* <img src={data.images[0]?.src} alt="" /> */}
                      </div>

                      <div className="footer">
                        <div className="p_title">{data?.name}</div>

                        <div className="actions">
                          <HiDownload className="icon" />

                          {user?.cart?.includes(data?.id) ? (
                            <FaHeart className="icon" />
                          ) : (
                            <FaRegHeart className="icon" />
                          )}
                        </div>
                      </div>
                    </Link>
                  </div>
                );
              })}
          </Slider>
        </div>
      )}

      <ModalComponent
        name={modalData?.name}
        showModal={showModal}
        modalClose={handleModalClose}
      />
    </>
  );
};

export default RelatedProducts;
