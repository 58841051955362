import React from "react";
import "./style.scss";
import { Link } from "react-router-dom";

// packages
import { useTranslation } from "react-i18next";

// images
import Vector2 from "../../../assets/images/home/Vector2.png";
import heroImage from "../../../assets/images/home/hero-image.png";

// components
import Navbar from "../../Navbar";

export default function HeroSection() {
  const { t } = useTranslation();

  return (
    <div className="hero-section">
      <Navbar isHomeNavbar />

      <div className="main-container">
        <div className="content">
          <div className="wordCarousel">
            <span>BIM OBJECTS FOR</span>
            <div>
              <ul className="flip4">
                <li>ARCHITECTS</li>
                <li>{t("word-carasoul.1")}</li>
                <li>{t("word-carasoul.2")}</li>
              </ul>
            </div>
          </div>

          <p className="para">
            Bring your vision to life with the best BIM objects available!
          </p>

          <Link to="/product" className="browse-btn browse-btn-web">
            <img src={Vector2} alt="" />
            Browse the library
          </Link>
        </div>

        <div className="hero-image">
          <img src={heroImage} alt="" className="zoom-in" />
        </div>

        <Link to="/product" className="browse-btn browse-btn-mbl">
          <img src={Vector2} alt="" />
          Browse the library
        </Link>
      </div>
    </div>
  );
}
