import React from "react";
import listOfBrands from "../../assets/brand-images/brand_logos.json";
// import brand from "../../assets/images/product/brand.png";

const Brand = (props) => {
  const { product } = props;
  let brandImg = "";

  if (typeof(product?.brandLogo) !== 'undefined') {
    brandImg = product?.brandLogo;
  } else if (typeof(product.brandSlug) !== 'undefined') {
      //  const index = listOfBrands.map(object => object.brand).indexOf(brand?.brandName[0]);
      const index = listOfBrands.map(object => object.brand).indexOf(product?.brandSlug);

      if (index !== -1) {
      brandImg = listOfBrands[index].image;
      }
  }

  return (
        // <img src={`/brandImages/${brandImg}`} alt=""/>
        <img src={`https://cdn.bimroom.com/brand-images/${brandImg}`} alt=""/>
  );
};

export default Brand;
