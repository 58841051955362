import React, { Suspense } from "react";
import { BrowserRouter } from "react-router-dom";
import ReactDOM from "react-dom";

// packages
import TagManager from "react-gtm-module";
import { PublicClientApplication } from "@azure/msal-browser";
import { MsalProvider } from "@azure/msal-react";
import { msalConfig } from "./authConfig";

// redux
import { store } from "./redux/store";
import { Provider } from "react-redux";

// components
import App from "./App";
import Loader from "./components/Loader";
import ScrollToTop from "./components/shared/ScrollToTop";

// styles
import "./index.css";
import "./Theme.css";
import "bootstrap/dist/css/bootstrap.min.css";

//
import "./i18Next";
import reportWebVitals from "./reportWebVitals";

const msalInstance = new PublicClientApplication(msalConfig);

const tagManagerArgs = { gtmId: "GTM-T6ZLXPP" };

TagManager.initialize(tagManagerArgs);

ReactDOM.render(
  <MsalProvider instance={msalInstance}>
    <Provider store={store}>
      <BrowserRouter>
        <ScrollToTop>
          <Suspense fallback={<Loader />}>
            <App />
          </Suspense>
        </ScrollToTop>
      </BrowserRouter>
    </Provider>
  </MsalProvider>,

  document.getElementById("root")
);

reportWebVitals();
