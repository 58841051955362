import React, { useState, useEffect } from "react";

// packages
import TagManager from "react-gtm-module";
import { useLocation, useNavigate } from "react-router-dom";

// api
// import { getProductBySlug } from "../api";

// components
//import Navbar from "../components/Navbar";
// import ProductPage from "../components/Product";
//import Footer from "../components/Footer";
//import Loader from "../components/Loader";

//
import { AuthenticatedTemplate, UnauthenticatedTemplate } from "@azure/msal-react";
import UnauthenticatedAccess from "../components/Home/UnauthenticatedAccess";
import Redirect from "../components/Redirect";
const RedirectPage = () => {
  const location = useLocation();

  const navigate = useNavigate();
  const [isBackButtonClicked, setBackbuttonPress] = useState(false);
  const slug = location.pathname.split("/product/");
  const [product, setProduct] = useState([]);


  return (
    <>

      <AuthenticatedTemplate>
        <Redirect />
      </AuthenticatedTemplate>
      <UnauthenticatedTemplate>
        <UnauthenticatedAccess />
      </UnauthenticatedTemplate>
    </>
  );
};

export default RedirectPage;
