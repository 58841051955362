import React, { useState } from "react";

// packages
import { useMsal } from "@azure/msal-react";
import { Line } from "rc-progress";
import Swal from "sweetalert2";

// components
import Rating from "./Rating";
import Loader from "../../Loader";

// redux
import { useDispatch } from "react-redux";
import { setRatingAndReview } from "../../../redux/reducers/product-reducer";

// icons
import { FaRegStar } from "react-icons/fa";

// api
import { addReviewAndRating, getReviewAndRating } from "../../../api";

const ProductReview = ({ productID, productStats }) => {
  const dispatch = useDispatch();

  const { accounts } = useMsal();
  const [starHover, setStarHover] = useState();
  const [starActive, setStarActive] = useState(0);
  const [review, setReview] = useState("");
  const [isLoading, setIsLoading] = useState(false);

  const reviewsCalculation = (star) => {
    let temp =
      productStats?.reviews?.filter((val) => val?.rating === star)?.length ?? 0;

    let totalReviews = productStats?.reviews?.length ?? 100;

    return Math.round((temp / totalReviews) * 100);
  };

  const reset = () => {
    setReview("");
    setStarActive(0);
  };

  const submit = async () => {
    if (accounts[0]) {
      let payload = {
        email: accounts[0]?.username,
        productID,
        rating: starActive,
        review,
      };

      try {
        setIsLoading(true);
        await addReviewAndRating(payload);
        const response = await getReviewAndRating();
        dispatch(setRatingAndReview(response.data));
        setIsLoading(false);
        Swal.fire("Review Submitted", "Thank you for the feedback", "success");
        reset();
      } catch (err) {
        setIsLoading(false);
        console.log("product-review component api error =>", err.message);
      }
    } else {
      alert("Please login first");
    }
  };

  return (
    <>
      {isLoading ? (
        <Loader />
      ) : (
        <>
          <div className="rating-section">
            <div className="rating-header">
              <div className="rating-title">Ratings & Reviews</div>
              <div className="rating-rating">
                <div className="rating-wrapper">
                  <div className="rating-text">{productStats?.rating}</div>
                  <Rating active={productStats?.rating} />
                </div>
                <div className="rating-text">
                  ({productStats?.reviews?.length} Reviews )
                </div>
              </div>
            </div>

            {[5, 4, 3, 2, 1].map((data) => (
              <div className="rating-progress-wrapper" key={data}>
                <div className="rating-progress-text">{data}</div>
                <Line
                  percent={reviewsCalculation(data)}
                  strokeWidth={1}
                  strokeColor="#175C83"
                  className="rating-progress-line"
                />
                <div className="rating-progress-text">
                  {reviewsCalculation(data)}%
                </div>
              </div>
            ))}
          </div>

          {accounts[0] && (
            <div className="review-section">
              <div className="review-wrapper">
                <div className="review-header">
                  <div className="review-title">Leave a review</div>

                  <div
                    className="review-rating"
                    onMouseOut={() => setStarHover(-1)}
                  >
                    {[1, 2, 3, 4, 5].map((data, index) => (
                      <FaRegStar
                        className={
                          starActive
                            ? data <= starActive
                              ? "icon active"
                              : "icon"
                            : data <= starHover
                            ? "icon active"
                            : "icon"
                        }
                        key={index}
                        onMouseOver={() => setStarHover(data)}
                        onClick={() => setStarActive(data)}
                      />
                    ))}
                  </div>
                </div>

                <textarea
                  name="message"
                  rows="4"
                  className="textarea"
                  placeholder="Your review..."
                  value={review}
                  onChange={(e) => setReview(e.target.value)}
                />
              </div>

              <div className="btn-submit" onClick={submit}>
                Submit
              </div>
            </div>
          )}

          <div className="comments-section">
            {productStats?.reviews?.map((data, i) => (
              <Comment data={data} key={i} />
            ))}
          </div>
        </>
      )}
    </>
  );
};

export default ProductReview;

const Comment = ({ data }) => {
  return (
    <div className="comment-wrapper">
      <div className="comment-detail">
        <div className="title">{data?.email}</div>
        <Rating active={data?.rating} />
      </div>
      <div className="comment-text">{data?.review}</div>
    </div>
  );
};
