import React, { useState } from "react";

// packages
import { useMsal } from "@azure/msal-react";

// components
import AccountModal from "../Modal/Account";
import WebNavbar from "./WebNavbar";
import MobileNavbar from "./MobileNavbar";

export default function Navbar({ isHomeNavbar }) {
  const { accounts } = useMsal();
  const [openAccountModal, setAccountModal] = useState(false);
  const handleAccountModal = () => setAccountModal(!openAccountModal);

  return (
    <>
      <WebNavbar
        isHomeNavbar={isHomeNavbar}
        handleAccountModal={handleAccountModal}
      />
      <MobileNavbar
        isHomeNavbar={isHomeNavbar}
        handleAccountModal={handleAccountModal}
      />

      {accounts[0] && (
        <AccountModal
          showModal={openAccountModal}
          closeModal={handleAccountModal}
          account={accounts[0]}
        />
      )}
    </>
  );
}
