import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

// api calls
import { productToCart, getProductBySlug } from "../../../api";

// components
import DownloadModal from "../../Modal/Download";

// packages
import { Highlight } from "react-instantsearch-dom";
import { useMsal } from "@azure/msal-react";
import { useTranslation } from "react-i18next";
import TagManager from "react-gtm-module";

// redux
import { useSelector, useDispatch } from "react-redux";
import { setUser } from "../../../redux/reducers/user-reducer";

// icons
import { FaRegHeart, FaHeart } from "react-icons/fa";
import { HiDownload } from "react-icons/hi";

// images
import brand from "../../../assets/product images/brand.png";
import emptyImage from "../../../assets/empty-image.png";
import dlcould7 from "../../../assets/images/product/dlcould7.png";
import Brand from "../../shared/Brand";



const Card = ({ hit }) => {
  const { t, i18n } = useTranslation();
  const { accounts } = useMsal();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const user = useSelector((store) => store.user.user);
  const p_rating = useSelector((store) => store.product.productRatingAndReview);
  const toggleImage = useSelector((store) => store.product.toggleImage);
  const [showModal, setShowModal] = useState(false);
  const [downloadModalData, setDownloadModalData] = useState({});
  const [selectedProduct, setSelectedProduct] = useState("");
  const [pluginMode, setPluginMode] = useState(true);

  const getImage = (pImage, rImage) => {
    let outputImage = "";
    if (typeof(pImage) === "string" && pImage !== "") {
      outputImage = pImage;
    } else if (typeof(pImage) === "object" && pImage.length > 0) {
      outputImage = pImage[0];
    } else if (typeof(rImage) === "string" && rImage !== "") {
      outputImage = rImage;
    } else if (typeof(rImage) === "object" && rImage.length > 0) {
      outputImage = rImage[0]
    }
    return outputImage;
  }

  const handleCart = (product) => {
    if (accounts[0]) {
      
      let payload = {
        id: accounts[0]?.localAccountId,
        name: accounts[0]?.name,
        email: accounts[0]?.username,
        product: {
          id: product?.id,
          slug: product?.slug,
          name: product?.name,
          brandSlug: product?.brandSlug,
          brandLogo: product?.brandLogo,
          image: getImage(product?.pImage, product?.rImage)
        }
      };

      productToCart(payload)
        .then((data) => {
          dispatch(setUser(data));
        })
        .catch((err) => console.log("product error =>", err.message));
    } else {
      alert("Please login first...");
    }
  };

  useEffect(() => {
    const plugin = JSON.parse(localStorage.getItem("plugin"));
    if (plugin === "true") {
      setPluginMode(true);
    }
  }, [])

  useEffect(() => {
    if(showModal === true) {
      getProductBySlug(selectedProduct)
      .then((data) => {
        //console.log(data);
        const downloads = {
          rfa: data[0]?.rfa,
          rvt: data[0]?.rvt,
          ifc: data[0]?.ifc
        }
        //console.log(downloads);
        setDownloadModalData(downloads);
      })
      .catch((err) => console.log("product detail page error =>", err.message));
    }
  }, [showModal])

  const insertObject = (product) => {
    if (product?.rfa?.length > 0) {
      const productName = product?.rfa[0]?.newName;
      const newName = product?.rfa[0]?.name;
      if (productName) {
        navigate("/download-start?folder=rfa&id=" + productName + "&newFilename=" + encodeURIComponent(encodeURIComponent(newName)));      
      }
    }
  }

  const checkIfExists = (id) => {
    const index = user?.cart?.findIndex((item) => {
      return item.id === id
    });
    if (index !== -1) {
      return true;
    } else {
      return false;
    }
  }

  const moveProductDetailPage = (product) => {
    navigate(`/product/${product.slug}`);
  };

  const getImageFromAzureStorage = (image) => {
    return `https://cdn.bimroom.com/images/${image}`;
  };

  const handleModalClose = () => setShowModal(false);

  const handleModalShow = (product) => {
    setSelectedProduct(product?.slug);
    setShowModal(true);
  };

  return (
    <>
      <div className="p_card">
        <div className="heart" onClick={() => handleCart(hit)}>
            {/* {checkIfExists(hit?.post_id) ? ( */}
            {checkIfExists(hit?.id) ? (
              // <FaHeart color="#337ab7" />
              <FaHeart color="#175c83" />
            ) : (
              <FaRegHeart />
            )}
          </div>

        <div onClick={() => moveProductDetailPage(hit)}>
          <div className="p_body">
            <div className="thumbnail">
              {toggleImage ? (
                hit?.rImage?.length > 0 && hit?.rImage[0] !== "" ? (
                  <img src={getImageFromAzureStorage(hit?.rImage[0])} alt="" />
                ) : hit?.pImage?.length > 0 && hit?.pImage[0] !== "" ? (
                  <img src={getImageFromAzureStorage(hit?.pImage[0])} alt="" />
                ) : null
              ) : hit?.pImage?.length > 0 && hit?.pImage[0] !== "" ? (
                <img src={getImageFromAzureStorage(hit?.pImage[0])} alt="" />
              ) : hit?.rImage?.length > 0 && hit?.rImage[0] !== "" ? (
                <img src={getImageFromAzureStorage(hit?.rImage[0])} alt="" />
              ) : null}

              {!hit?.pImage && !hit?.rImage && (
                <img src={emptyImage} alt="" />
              )}
            </div>
            {/* <div className="p_title">
              <Highlight attribute="post_title" tagName="mark" hit={hit} />
            </div> */}
          </div>
        </div>

        {/* <div className="brand">
          <Brand product={hit} />
        </div> */}
          <div className="p_title">
            <Highlight attribute="name" tagName="mark" hit={hit} />
          </div>

        <div className="footer">

          {/* <div className="emptyDiv">
          
          </div> */}

          <div className="brand">
            <Brand product={hit} />
          </div>
          {/* <div className="p_title">
            <Highlight attribute="name" tagName="mark" hit={hit} />
          </div> */}


          {/* {pluginMode === true
            ? <div className="download-btn" onClick={() => insertObject(hit)}>
                <HiDownload className="icon" />
              </div>
            :   <div className="download-btn" onClick={() => handleModalShow(hit)}>
                <HiDownload className="icon" />
              </div>
          } */}
          <div className="download-btn" onClick={() => pluginMode ? insertObject(hit) : handleModalShow(hit)}>
            <HiDownload className="icon" />
          </div>
        </div>
      </div>

      <DownloadModal
        data={downloadModalData}
        name={hit?.name}
        brand={{
          brandLogo: hit?.brandLogo,
          brandSlug: hit?.brandSlug
        }}
        showModal={showModal}
        downloadObject={hit?.glb}
        modalClose={handleModalClose}
      />
    </>
  );
};

export default Card;
