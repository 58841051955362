import React, { useState, useEffect } from "react";
import { useNavigate, useLocation } from "react-router-dom";

// packages
import TagManager from "react-gtm-module";
import algoliasearch from "algoliasearch/lite";
import { InstantSearch } from "react-instantsearch-dom";
import { useDispatch, useSelector } from "react-redux";
import { resetAllFilters } from "../redux/reducers/product-reducer";

// components
import Navbar from "../components/Navbar";
import ProductCatalog from "../components/ProductCatalog";
import Footer from "../components/Footer";
import withURLSync from "../components/ProductCatalog/URLSync";

//
import { CLIENT_URL } from "../config";
import { AuthenticatedTemplate, UnauthenticatedTemplate } from "@azure/msal-react";
import UnauthenticatedAccess from "../components/Home/UnauthenticatedAccess";

const searchClient = algoliasearch(
  "MLN6C7QSR3",
  "d5d83c9e0103cbfeef63fcd712daa7e3"
);

const Catalog = (props) => {
  const navigate = useNavigate();
  const location = useLocation();
  const [isBackButtonClicked, setBackbuttonPress] = useState(false);
  const dispatch = useDispatch();

  useEffect(() => {
    TagManager.dataLayer({
      dataLayer: {
        event: "pageview",
        path: "/product",
      },
    });
  }, []);

  useEffect(() => {
    document.title = "Catalog | bimroom"
  },[])

  // useEffect(() => {
  //   return () => {
  //     dispatch(resetAllFilters());
  //   };
  // }, []);

  // useEffect(() => {
  //   window.history.pushState(null, null, window.location.pathname);
  //   window.addEventListener("popstate", onBackButtonEvent);
  //   return () => {
  //     window.removeEventListener("popstate", onBackButtonEvent);
  //   };
  // }, []);
  // const onBackButtonEvent = (e) => {
  //   e.preventDefault();
  //   if (!isBackButtonClicked) {
  //     setBackbuttonPress(true);
  //     window.location.replace(CLIENT_URL);
  //   }
  // };

  return (
    <>
      <AuthenticatedTemplate>
        <Navbar />
        <InstantSearch
          // indexName={"wp_posts_product"}
          indexName={"bimroom_demo_test"}
          searchClient={searchClient}
          searchState={props.searchState}
          createURL={props.createURL}
          onSearchStateChange={props.onSearchStateChange}
        >
          <ProductCatalog 
            searchState={props.searchState} 
          />
        </InstantSearch>
        <Footer />
      </AuthenticatedTemplate>
      <UnauthenticatedTemplate>
        <UnauthenticatedAccess />
      </UnauthenticatedTemplate>
    </>
  );
};

export default withURLSync(Catalog);
