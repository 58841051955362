import "./style.scss";

// components
import GoBack from "../shared/GoBack";
import Filters from "./Filters";
import Products from "./Products";
import ToggleSortSection from "./ToggleSortSection";

const ProductPage = ({searchState}) => {
  return (
    <>
      <div style={{ backgroundColor: "rgba(23, 92, 131, 0.1)" }}>
        <div className="max-width">
          <div className="product-listing-page-header">
            <GoBack prevPage={"/"} />

            <div className="ToggleSortSection-Web">
              <ToggleSortSection />
            </div>
          </div>

          <div className="product-listing-page">
            <Filters searchState={searchState}/>

            <div className="ToggleSortSection-Mobile">
              <ToggleSortSection />
            </div>

            <Products />
          </div>
        </div>
      </div>
    </>
  );
};

export default ProductPage;
