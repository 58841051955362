import { useNavigate } from "react-router-dom";

// images or icons
import { FaArrowLeft } from "react-icons/fa";

//
import "./style.scss";

export default function GoBack({ prevPage }) {
  const navigate = useNavigate();
  return (
    <div
      className="go-back-component"
      onClick={() => navigate(prevPage ? prevPage : -1)}
    >
      <FaArrowLeft className="icon" />
      <span>Back</span>
    </div>
  );
}
