// images & icons
import { FaRegStar } from "react-icons/fa";
import { AiFillStar } from "react-icons/ai";

const Rating = ({ active }) => {
  return (
    <div className="rating-icons">
      {[1, 2, 3, 4, 5].map((star, index) =>
        star <= active ? (
          <AiFillStar className="icon active" key={index} />
        ) : (
          // <AiFillStar className="icon" key={index} />
          <FaRegStar className="icon border-star" key={index} />
        )
      )}
    </div>
  );
};

export default Rating;
