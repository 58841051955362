import React from "react";

// packages
import { Hits } from "react-instantsearch-dom";
import { connectInfiniteHits } from "react-instantsearch/connectors";

// components
import Card from "./Card";
import {
  CustomCurrentRefinements,
  PaginationAlgolia,
  NoResults,
} from "../../Widget";

export default function Product() {
  return (
    <div className="product-section">
      <div className="refine-tag-wrapper">
        <CustomCurrentRefinements />
      </div>

      {/* <Hits hitComponent={Card} /> */}
      <CustomInfiniteHits />
      <NoResults />

      {/* <div className="pagination-wrapper">
        <div className="pagination">
          <PaginationAlgolia />
        </div>
      </div> */}
    </div>
  );
}

const CustomInfiniteHits = connectInfiniteHits(
  ({ hits, hasMore, refineNext }) => {
    return (
      <>
        <div className="ais-InfiniteHits-root">
          {hits.map((hit) => (
            <Card key={hit.objectID} hit={hit} />
          ))}
        </div>

        {hasMore && (
          <div className="ais-InfiniteHits-loadMore">
            <span onClick={refineNext}>Show More</span>
          </div>
        )}
      </>
    );
  }
);
