import React from "react";

// images & icons
import menuDark from "../../../assets/navbar/menu-dark.svg";
import searchDark from "../../../assets/navbar/search-dark.svg";

// components
import MenuDrawer from "./MenuDrawer";
import SearchDrawer from "./SearchDrawer";
import NavbarHeader from "./NavbarHeader";

export default function MobileNavbar({ isHomeNavbar, handleAccountModal }) {
  const [openMenu, setMenuDrawer] = React.useState(false);
  const [openSearch, setSearchDrawer] = React.useState(false);
  return (
    <>
      <NavbarHeader
        isHomeNavbar={isHomeNavbar}
        leftImage={menuDark}
        onCLickLeftImage={() => setMenuDrawer(true)}
        RightImage={searchDark}
        onCLickRightImage={() => setSearchDrawer(true)}
      />

      {openMenu && (
        <MenuDrawer
          open={openMenu}
          onClose={() => setMenuDrawer(false)}
          openSearchDrawer={() => setSearchDrawer(true)}
          handleAccountModal={handleAccountModal}
        />
      )}

      {openSearch && (
        <SearchDrawer
          open={openSearch}
          onClose={() => setSearchDrawer(false)}
          openMenuDrawer={() => setMenuDrawer(true)}
        />
      )}
    </>
  );
}
