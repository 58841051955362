import React, { useEffect } from "react";

// packages
import { useMsal } from "@azure/msal-react";
import { Routes, Route, useSearchParams } from "react-router-dom";
import { useDispatch } from "react-redux";
import { setRatingAndReview } from "./redux/reducers/product-reducer";
import { setUser, setKey, setProjects, setUserState } from "./redux/reducers/user-reducer";
import { useLocation } from "react-router-dom";
import { v4 as uuidv4 } from 'uuid';

// components
import Home from "./pages/Home";
import Favorite from "./pages/Favorite";
import Catalog from "./pages/Catalog";
import Product from "./pages/Product";
import Blog from "./pages/Blog";
import DownloadStart from "./pages/DownloadStart";

// api's
import { getProjects, createUser, getUserDetail, getReviewAndRating, getReviews, getKey } from "./api";
import RedirectPage from "./pages/RedirectPage";

function App() {
  const { instance, accounts } = useMsal();
  let [searchParams, setSearchParams] = useSearchParams();

  const dispatch = useDispatch();
  const location = useLocation();

  useEffect(async () => {
    if (accounts[0]) {
      try {
        let response = await createUser({
          id: accounts[0]?.localAccountId,
          name: accounts[0]?.name,
          email: accounts[0]?.username,
          cart: [],
          projects: [uuidv4()],
          //searchKey: "",
        });
        dispatch(setUser(response));
        if (response) {
          let projects = await getProjects({
            id: accounts[0]?.localAccountId
          });
          //console.log(projects);
          dispatch(setProjects(projects));
        }
        dispatch(setUserState(false));
      } catch (err) {
        dispatch(setUser({}));
        //dispatch(setUserState(false));
        dispatch(setProjects([]));
      }
    } else {
      dispatch(setUser({}));
      //dispatch(setUserState(false));
      dispatch(setProjects([]));
    }
  }, [accounts[0]]);

  // useEffect(async () => {
  //   try {
  //     let response = await getReviewAndRating();
  //     dispatch(setRatingAndReview(response.data));
  //   } catch (err) {
  //     console.log("App page get product api error =>", err.message);
  //   }
  // }, []);

  useEffect(() => {
    const value = searchParams.get("plugin");
    if (value) {
      // console.log(value[0]);
      //let array = ["Silka","Ytong"];
      localStorage.setItem("plugin", JSON.stringify(value));
    }
  }, []);


  useEffect(() => {
    const temp = window?.location?.href?.split("/");
    const temp1 = temp[3]?.split("=");

    if (temp1?.length > 0 && temp1[0] === "#id_token") {
      instance.logoutRedirect().catch((e) => {
        console.error(e);
      });
    }
  }, [location?.pathname]);

  // useEffect(() => {
  //   if (window.performance) {
  //     if (performance.getEntriesByType("navigation")) {
  //       if (location.pathname.includes("/productsearch")) {
  //         window.location.replace(`https://bimifyalgolia.netlify.app/product`);
  //       }
  //     }
  //   }
  // }, []);

  return (
    <>
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/product" element={<Catalog />} />
        <Route path="/product/search/*" element={<Catalog />} />
        <Route path="/product/:id" element={<Product />} />
        <Route path="/favorite" element={<Favorite />} />
        <Route path="/blog" element={<Blog />} />
        <Route path="/download-start" element={<DownloadStart />} />
        <Route path="/redirect" element={<RedirectPage />} />
      </Routes>
    </>
  );
}

export default App;
