import React, { useEffect } from "react";
import Navbar from "../components/Navbar";
import BlogPage from "../components/Blog";
import Footer from "../components/Footer";
import TagManager from "react-gtm-module";
import { AuthenticatedTemplate, UnauthenticatedTemplate } from "@azure/msal-react";
import UnauthenticatedAccess from "../components/Home/UnauthenticatedAccess";

const Blog = () => {
  useEffect(() => {
    TagManager.dataLayer({
      dataLayer: {
        event: "pageview",
        path: "/blog",
      },
    });
  }, []);
  return (
    <>
      <AuthenticatedTemplate>
        <Navbar />
        <BlogPage />
        <Footer />
      </AuthenticatedTemplate>
      <UnauthenticatedTemplate>
        <UnauthenticatedAccess />
      </UnauthenticatedTemplate>
    </>
  );
};

export default Blog;
