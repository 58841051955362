import { useEffect, useState } from "react";
// packages
import {
  ClearRefinements,
  RefinementList,
  HierarchicalMenu,
  Stats,
  Panel,
} from "react-instantsearch-dom";

// redux
import { useSelector } from "react-redux";

// components
import { CustomAlgoliaSearch, NumSlider, Ratings } from "../../Widget";
import Collapsible from "../../shared/Collapsible";


export default function Filter({searchState}) {
  const searched = useSelector((store) => store.product.search);
  const selectedCategory = useSelector((store) => store.product.category);
  const [category, setCategory] = useState("");

  useEffect(() => {
    // if (typeof searchState?.hierarchicalMenu != "undefined") {
    //   if ("taxonomies_hierarchical.product_cat.lvl0" in searchState?.hierarchicalMenu) {
    //     setCategory(searchState?.hierarchicalMenu["taxonomies_hierarchical.product_cat.lvl0"]);
    //   }
    // }
    if (typeof searchState?.hierarchicalMenu != "undefined") {
      if ("categories.lvl0" in searchState?.hierarchicalMenu) {
        setCategory(searchState?.hierarchicalMenu["categories.lvl0"]);
      }
    }
  }, [searchState])

  return (
    <div className="filter-wrapper">
      <div className="filter-text">Filters</div>
      <div className="result-text">
        <Stats />
      </div>

      <div className="input-search">
        <CustomAlgoliaSearch defaultRefinement={searched} />
      </div>

      <div className="category-search">
        <Collapsible title="Category" defaultExpanded={true}>
          <Panel>
            <HierarchicalMenu
              limit={100}
              attributes={[
                "categories.lvl0",
                "categories.lvl1",
                "categories.lvl2",
              ]}
            />
          </Panel>
        </Collapsible>
      </div>

      <div className="brand-search">
        <Collapsible title="Brands" defaultExpanded={false}>
          <Panel>
            <RefinementList
              attribute="brandName"
              limit={2000}
              searchable={true}
              translations={{
                placeholder: "Search for brands…",
                resetTitle: "",
              }}
            />
          </Panel>
        </Collapsible>
      </div>

      <div className="file-type">
        <Collapsible title="Filetype" defaultExpanded={false}>
          <Panel>
            <RefinementList attribute="attributes.filetype" limit={2000} />
          </Panel>
        </Collapsible>
      </div>

      <div className="dimensions">
        <Collapsible title="Dimensions" defaultExpanded={false}>
          <Panel className="slim-panel"  header="Height">
            <NumSlider attribute="attributes.height" unit="mm"  />
          </Panel>
          <Panel className="slim-panel" header="Width" >
            <NumSlider attribute="attributes.width" unit="mm"  />
          </Panel>
          <Panel className="slim-panel" header="Length">
            <NumSlider attribute="attributes.length" unit="mm"   />
          </Panel>
          <Panel className="slim-panel" header="Depth">
            <NumSlider attribute="attributes.depth" unit="mm"  />
          </Panel>
        </Collapsible>
      </div>

      {category !== "" &&
        <div className="file-type">
          <Collapsible title="Fire rating" defaultExpanded={false}>
            <Panel>
              <RefinementList attribute="attributes.fireRating" limit={2000} />
            </Panel>
          </Collapsible>
        </div>
      }

      <div className="rating">
        <Collapsible title="Ratings">
          <Panel>
            <Ratings attribute="average_rating" />
          </Panel>
        </Collapsible>
      </div>

      <div className="reset-button">
        <ClearRefinements
          clearsQuery
          translations={{
            reset: "Reset all filters",
          }}
        />
      </div>
    </div>
  );
}
