import { useState, useRef, useEffect } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import "./style.scss";

// packages
import { Box, Drawer } from "@mui/material";

// redux
import { useDispatch } from "react-redux";
import {
  setSearch,
  setCategory,
} from "../../../../redux/reducers/product-reducer";

// components
import NavbarHeader from "../NavbarHeader";
import MenuLink from "../../MenuLink";

// constants
import { CATEGORY } from "../../../../constants/category";

//  images
import closeWhite from "../../../../assets/navbar/close-white.svg";
import menuWhite from "../../../../assets/navbar/menu-white.svg";
import downIcon from "../../../../assets/images/navbar/down-icon.png";
import { FaSearch, FaAngleDown } from "react-icons/fa";

export default function SearchDrawer({ open, onClose, openMenuDrawer }) {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const dropDownRef = useRef(null);

  const [dropDown, setDropDown] = useState(false);
  const [selectedCategory, setSelectedCategory] = useState("");
  const [input, setInput] = useState("");

  const handleAlgoliaSearch = (e) => {
    e.preventDefault();
    if (selectedCategory) dispatch(setCategory(selectedCategory));
    dispatch(setSearch(input));
    onClose();
    if (location.pathname != "/product") navigate("/product");
  };

  const sortCategory = (a, b) => {
    const labelA = a.label.toUpperCase();
    const labelB = b.label.toUpperCase();
    return labelA < labelB ? -1 : labelA > labelB ? 1 : 0;
  };

  useEffect(() => {
    /**
     * Alert if clicked on outside of element
     */
    function handleClickOutside(event) {
      if (dropDownRef.current && !dropDownRef.current.contains(event.target)) {
        setDropDown(false);
      }
    }

    // Bind the event listener
    document.addEventListener("mousedown", handleClickOutside);

    return () => {
      // Unbind the event listener on clean up
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [dropDownRef]);

  return (
    <Drawer
      open={open}
      onClose={onClose}
      sx={{
        "& .MuiDrawer-paper": {
          boxSizing: "border-box",
          width: "100%",
        },
      }}
    >
      <Box sx={{ backgroundColor: "#175C83", minHeight: "100vh" }}>
        <NavbarHeader
          leftImage={menuWhite}
          onCLickLeftImage={() => {
            onClose();
            openMenuDrawer();
          }}
          RightImage={closeWhite}
          onCLickRightImage={onClose}
          isWhite
        />

        <div className="navbar-search-drawer">
          <div
            className="categories"
            ref={dropDownRef}
            onClick={() => setDropDown(!dropDown)}
          >
            <div className="category-select">
              <div className="text">
                {selectedCategory ? selectedCategory : "Categories"}
              </div>
              <FaAngleDown className="icon" />
            </div>

            <div className={dropDown ? "drop-down-open" : "drop-down-close"}>
              {[...CATEGORY].sort(sortCategory).map((data, idx) => (
                <MenuLink
                  icon={data.icon}
                  label={data.label}
                  key={idx}
                  onClick={() => setSelectedCategory(data.label)}
                  active={selectedCategory}
                />
              ))}
            </div>
          </div>

          <div className="search-filter">
            <input
              type="text"
              placeholder={"Search..."}
              value={input}
              onChange={(e) => setInput(e.target.value)}
            />

            <div className="icon" onClick={(e) => handleAlgoliaSearch(e)}>
              <FaSearch />
            </div>
          </div>
        </div>
      </Box>
    </Drawer>
  );
}
