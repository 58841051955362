import React from "react";

// packages
import { Table } from "react-bootstrap";

// components
import Location from "./location-svg";
import Collapsible from "../../shared/Collapsible";
import { useTranslation } from "react-i18next";

export default function ProductInfo({ product }) {

  const { t, i18n } = useTranslation();
  
  const general = product?.general;

  const dimensions = product?.dimensions;

  const technicalDetails = product?.technicalDetails;

  const classification = product?.classification;

  const availabilityNew = product?.availability;

  return (
    <>
      <Collapsible title={"Product Information"} defaultExpanded={true}>
        {Object.keys(general).length !== 0 && (
            <>
            <Table responsive>
              <tbody>
                <tr>
                  <th className="table_title">General</th>
                  <td className="table_data"></td>
                </tr>
                {Object.keys(general).map((key, index) => {
                  return (
                    general[key] !== "" && (
                      <>
                        <tr key={index}>
                          <td className="table_heading">{t("general." + key)}</td>
                          <td className="table_data">{general[key]}</td>
                        </tr>
                      </>
                    )
                  );
                })}
              </tbody>
            </Table>
          </>
        )}

        {Object.keys(dimensions).length !== 0 && (
            <>
            <Table responsive>
              <tbody>
                <tr>
                  <th className="table_title">Dimensions</th>
                  <td className="table_data"></td>
                </tr>
                {Object.keys(dimensions).map((key, index) => {
                  return (
                    dimensions[key] !== "" && (
                      <>
                        <tr key={index}>
                          <td className="table_heading">{t("dimensions." + key)}</td>
                          <td className="table_data">{dimensions[key]}</td>
                        </tr>
                      </>
                    )
                  );
                })}
              </tbody>
            </Table>
          </>
        )}
        {Object.keys(technicalDetails).length !== 0 && (
            <>
            <Table responsive>
              <tbody>
                <tr>
                  <th className="table_title">Technical Details</th>
                  <td className="table_data"></td>
                </tr>
                {Object.keys(technicalDetails).map((key, index) => {
                  return (
                    technicalDetails[key] !== "" && (
                      <>
                        <tr key={index}>
                          <td className="table_heading">{t("technicalDetails." + key)}</td>
                          <td className="table_data">{technicalDetails[key]}</td>
                        </tr>
                      </>
                    )
                  );
                })}
              </tbody>
            </Table>
          </>
        )}
      </Collapsible>

      <Collapsible title={"Classification"} defaultExpanded={false}>
      <Table responsive>
          <tbody>
              {Object.keys(classification).map((key, index) => {
                return (
                  classification[key] !== "" && (
                    <>
                      <tr key={index}>
                        <td className="table_heading">{t("classification." + key)}</td>
                        <td className="table_data">{classification[key]}</td>
                      </tr>
                    </>
                  )
                );
              })}

          </tbody>
        </Table>
      </Collapsible>

      <Collapsible title={"Availability"} defaultExpanded={false}>
        <div>
          <Location />
        </div>
      </Collapsible>
    </>
  );
}
