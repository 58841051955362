import React, { useMemo } from "react";

// component's
//import ActionButtons from "./ActionButtons";
import Description from "./Description";
import ProductInfo from "./ProductInfo";
import ProductReview from "./ProductReview";
import Rating from "./Rating";

// redux
import { useSelector } from "react-redux";

export default function ProductDetail({ product }) {
  const { name, description } = product;

  const p_rating_review = useSelector(
    (store) => store.product.productRatingAndReview
  );

  const productStats = useMemo(
    () => p_rating_review?.find((id) => id.productID === `${product?.id}`),
    [p_rating_review]
  );

  return (
    <div className="product-detail">
      {/* <div className="brand">
        <img src={"/images/brand.png"} alt="" />
      </div> */}

      <div className="title">{name}</div>
      <Rating active={productStats} />
      {/* <ActionButtons product={product} /> */}
      <Description description={description} />
      <ProductInfo product={product} />
      <ProductReview productID={product?.id} productStats={productStats} />
    </div>
  );
}
