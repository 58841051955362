import React from "react";

// packages
import Switch from "react-switch";

// redux
import { useSelector, useDispatch } from "react-redux";
import { setToggleImage } from "../../../redux/reducers/product-reducer";

export default function ToggleSwitch() {
  const dispatch = useDispatch();
  const toggle = useSelector((store) => store.product.toggleImage);
  const handleToggle = () => dispatch(setToggleImage());

  return (
    <div className="handleSwitchToggle">
      <Switch
        checked={toggle}
        onChange={handleToggle}
        offColor="#cccccc"
        onColor="#175C83"
        offHandleColor="#fff"
        onHandleColor="#fff"
        activeBoxShadow="0px 0px 1px 2px #fff"
        id="small-radius-switch"
        uncheckedIcon={<div></div>}
        checkedIcon={<div></div>}
      />
      <div className="text">{toggle ? "Rendered image" : "Product image"}</div>
    </div>
  );
}
