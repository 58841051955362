import "./style.scss";

// components
import ProductView from "./ProductView";
import ProductDetail from "./ProductDetail";
import RelatedProducts from "./RelatedProducts";
import GoBack from "../shared/GoBack";
import Brand from "../shared/Brand";
import { useEffect } from "react";

const ProductPage = ({ product }) => {

  useEffect(() => {
    document.title = product?.brandName + " - " + product?.name + " | bimroom"
  },[])

  return (
    <>
      <div className="bimroom-product-page">
        
        <div className="product-view-header-section">
          <GoBack />
          <div className="product-view-brand">
            <Brand product={product} />
          </div>
          
        </div>

        <div className="product-view-detail-section">
          <ProductView product={product} />
          <ProductDetail product={product} />
        </div>
      </div>

      <div style={{ backgroundColor: "rgba(23, 92, 131, 0.1)" }}>
        <RelatedProducts relatedProductIDs={product?.related_ids} />
      </div>
    </>
  );
};

export default ProductPage;
